
// Content styles
.block-text,
.biography, .section-library .centre {
	line-height: 1.7;
	
	// Default paragraphs
	p {
		margin: 0 0 $paragraph-margin;

		// &.intro {
		// 	padding: 0 0 $paragraph-margin;
		// 	border-bottom: $border;
		// 	color: $color-sub;
		// 	font-family: $font-family-sub;
		// 	font-size: $h4;
			
		// 	@media #{$mobileXL} {
		// 		font-size: $h3;
		// 	}
		// }

		a {
			font-weight: $font-bold;
			text-decoration: underline;

			&:hover, &:focus {
				text-decoration: none;
			}
		}
	}

	// reset any <ul> that will appear in a template here
	// maintain vertical rhythm with paragraph margin
	ul[class] {
		@extend %reset-ul;
		margin: 0 0 $paragraph-margin;
	}
	
	// New default <ul> lists
	ul {
		@extend %default-ul;
		padding-left: $block-padding*2;
	}
	
	// New default <ol> lists
	ol {
		@extend %default-ol;
		padding-left: $block-padding*2;
	}
}

img {
	max-width: 100%;
}